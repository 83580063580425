*{
  box-sizing: border-box;
  
}

body {
  margin: 0;
  font-family: 'Eudoxus Sans', -apple-system, BlinkMacSystemFont, "Segoe UI", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  box-sizing: border-box;
  background-color: #171717;
  color: white;
  overflow-x: hidden;
}

li {
  list-style: none;
  display: inline;
  
}

a {
  text-decoration: none;
}

span {
color: white;
}

.glass{
  background: rgba(39, 35, 35, 0.3);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(39, 35, 35, 0.37);
}

.glass-solid{
  background: rgba(39, 35, 35, 0.950);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(39, 35, 35, 0.37);
}

.Iw-header{
  background-color: rgb(31, 30, 30);
  z-index: 1000;
}

.Iw-header__mobile {
background-color: rgb(31, 30, 30);
}

.Iw-header__logo {
  display: flex;
}

.Iw-Inu{
  margin-top: 20px;
  margin-left: 0px;
  height: 40px;
}

.Iw-header__Anime {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
}

.Iw-header__Link ul{
  margin-top: 25px;
  margin-left: 30px;
}

.Iw-header__Link ul li a{
  margin-right: 50px;
  font-family: "eudoxus sans";
  font-weight: 500;
  font-size: 18px;
  color: #dadada;
}

.Iw-Search{
  display: flex;
}

.right{
margin-right: 50px;
}

.Iw-header__input{
  margin-top: 20px;
  position: relative;
  width: 250px;
  height: 30px;
  outline: none;
  margin-right: 30px;
}

.Iw-header__Search{
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 10px 55px;
  border-radius: 12px;
  font-size: 15px;
  background: rgb(31, 30, 30);
  outline: none;
  border: none;
  color: #dadada;
  font-family: "eudoxus sans";
}

.fa.fa-search{
  color: #dadada;
}

.Iw-header__input .icon{
  height: 55px;
  width: 70px;
  line-height: 55px;
  position: absolute;
  text-align: center;
  margin-top: -11px;
  font-size: 13px;
}

.Iw-authenticated-user-menu {
  display: flex;
}

.Iw-header-tile {
  align-items: center;
  color: #dadada;
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding-left: 0.8125rem;
  padding-right: 0.8125rem;
  transition: background-color .1s cubic-bezier(0,0,.25,1);
}



.imagepre{
border-radius: 6px;
}

.Iw-header-avatar {
  border-radius: 50%;
  flex: 0 0 2.125rem;
  height: 2.125rem;
  overflow: hidden;
  position: relative;
  width: 1.825rem;
  background-image: url(https://media.melty.fr/article-4094183-ratio15_720-f5/media.jpg);
  background-size: contain;
  background-repeat: no-repeat;
  margin-top: 20px;
  margin-right: -10px;
}

.Iw-content-image__image {
  background-color: #dadada;
  display: block;
  height: 100%;
  width: 100%;
}

.Iw-content-image__figure {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.Iw-avatar .Iw-avatar__image {
  height: calc(100% + 0.125rem);
  left: -0.0625rem;
  position: absolute;
  top: -0.0625rem;
  width: calc(100% + 0.125rem);
}

.Iw-authenticated-user-menu .dropdown-icon {
  margin-left: 0.375rem;
  padding: 0;
}

.Iw-header-svg {
  align-items: center;
  color: #dadada;
  display: flex;
  justify-content: center;
  padding: 0 0.3125rem;
}

.Iw-header-svg .header-svg-icon {
  fill: currentColor;
  height: 1.5rem;
  transition: fill .2s ease;
  margin-right: 50px;
}

.right{
  align-items: right;
  display: inline-flex;
}

/*    DARK MODE    */
.dark-mode{
  background-color: white;
  color: #171717;
}

.dark-mode .last-ep-time {
  color: #fff!important;
}

.dark-mode span {
  color: #171717;
}

.dark-mode .nodarkmode{
    color:  #5a66bf;
}

.dark-mode p{
  color: #000000;
}

.dark-mode .see-all{
  color: #5a66bf;
}

.dark-mode .iconfont {
 color: #5a66bf;
}

.dark-mode .tw_button-sun{
  display: flex;
}

.dark-mode .tw_button-moon{
  display: none;
}





.bg,
.button {
position: absolute;
width: 32px;
height: 32px;
border-radius: 100%;
}



.bg {
animation: pulse 1.2s ease infinite;
background: #5a66bf;
}

.button {
display: flex;
justify-content: center;
align-items: center;
position: absolute;
z-index: 99;
border: none;
background-color: #5a66bf;
background-size: 9px;
cursor: pointer;
outline: none;
}

.button a {
position: absolute;
color: #FFF;
font-size: 15px;
}

@keyframes pulse {
0% {
  transform: scale(1, 1);
}
50% {
  opacity: 0.3;
}
100% {
  transform: scale(1.5);
  opacity: 0;
}
}

.tw_button-moon {
display: flex;
justify-content: right;
margin-right: 10px;
margin-top: -38px;
}
.tw_button-sun{
display: none;
justify-content: right;
margin-right: 10px;
margin-top: -35px;
}

.Iw-Search-mobile{
  display: none;
}

.tw-header__Mobile-menu{
  width: 15px;
  height: 40px;
  flex-direction: column;
  cursor: pointer;
  display: none;
}

.stick {
 width: 23px;
 height: 3px;
 border-radius: 3px;
 margin-bottom: 5px;
 background-color:  #ffffff;
 display: inline-block;
}

.stick:last-child {
 margin-bottom: 0px;
}

.search-input-wrapper{
  display: none;
}
/*                    */

@media only screen and (max-width: 1024px) {
   .Iw-Search{
       display: none;
   }

   .Iw-Search-mobile{
    align-items: center;
    color: #dadada;
    display: flex;
    justify-content: center;
    padding: 0 0.6125rem;
    margin-top: 20px;
    margin-right: 10px;
    z-index: 1001;
    position: relative;
 }

   .erc-search-header-button {
      display: flex;
  }

  .erc-header-tile {
      align-items: center;
      color: #dadada;
      cursor: pointer;
      display: flex;
      justify-content: center;
      padding-left: 0.8125rem;
      padding-right: 0.8125rem;
      transition: background-color .1s cubic-bezier(0,0,.25,1);
  }

  .header-svg-icon {
      fill: currentColor;
      height: 1.8rem;
      margin-left: -15px;
  }

  .tw-header__Mobile-menu {
      z-index: 99;
      display: flex;
      margin-left: 25px;
      margin-top: 30px;
      margin-right: 60px;
    }

    .Iw-Inu{
      margin-top: 20px;
      height: 40px;
  }

  .Iw-header__Link{
      display: none;
  }

   .search-header-wrapper {
      background-color: rgb(31, 30, 30);
  }
  
  .erc-search-field {
      padding-bottom: 1.5rem;
      padding-top: 1.5rem;
  }
  
  .c-content-wrapper {
      box-sizing: border-box;
      margin: 0 auto;
      max-width: calc(65.625rem + 1.25rem*2);
      padding-left: 1.25rem;
      padding-right: 1.25rem;
      margin-top: 80px;
  }
  
  .erc-search-field .search-input-wrapper {
      margin: 0 auto;
      max-width: 55rem;
  }
  
  .erc-search-field .search-input {
      text-rendering: optimizeLegibility;
      background-color: transparent;
      border: none;
      outline: none;
      border-bottom: 0.125rem solid #e69265;
      color: rgb(255, 255, 255);
      display: block;
      font-family: Lato,Helvetica Neue,helvetica,sans-serif;
      font-size: 15px;
      font-weight: 600;
      line-height: 1.75rem;
      padding-bottom: 0.25rem;
      transition: border-color .2s cubic-bezier(.5,0,.25,1);
      width: 100%;
  }

  .search-input-wrapper{
      display: flex;
  }

  .Iw-header-avatar {
    margin-right: 10px;
  }
}

.minia{
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 0.75rem;
}
@media (max-width: 1730px) { 
  .minia{
    grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 0.5rem;
}
}

@media (max-width: 1470px) { 
  .minia{
    grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 0.25rem;
}
}


@media only screen and (max-width: 465px) {
  .bg,
  .button {
    position: absolute;
    width: 25px;
    height: 25px;
    border-radius: 100%;
  }

  .tw_button-moon {
    display: flex;
    justify-content: right;
    margin-right: 20px;
    margin-top: 25px;
  }
  .tw_button-sun{
    display: none;
    justify-content: right;
    margin-right: 20px;
    margin-top: 25px;
  }

  .button a {
    position: absolute;
    color: #FFF;
    font-size: 12px;
  }

  .Iw-header-svg .header-svg-icon {
    fill: currentColor;
    height: 1.2rem;
    transition: fill .2s ease;
    margin-right: 30px;
}

.Iw-header-tile {
  align-items: center;
  color: #dadada;
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding-left: 0.0125rem;
  padding-right: 0.0125rem;
  transition: background-color .1s cubic-bezier(0,0,.25,1);
}

.header-svg-icon {
fill: currentColor;
height: 1.5rem;
transition: fill .2s ease;
margin-top: 5px;
}

.Iw-Inu{
margin-top: 20px;
height: 35px;
width: 120px;
}

.Iw-header__Anime {
display: flex;
justify-content: flex-start;
margin: 0 auto;
}

.right {
margin-left: 50px;
}

}

@media (min-width: 1025px) {
.LibraryPage-header {
  position: relative;
  background-size: cover;
  background-position: 50%;
  background-attachment: fixed;
  min-height: 494px;
  text-align: center;
  padding: 25px;
}
}

@media (min-width: 1025px) {
.LibraryPage-header2 {
   position: relative;
   background-image: url(https://lecturesmmdoriane.files.wordpress.com/2021/06/sabito.gif);
   background-size: cover;
   background-position: 50%;
   background-attachment: fixed;
   z-index: 10;
   min-height: 494px;
   text-align: center;
   padding: 35px;
}

}

.tw_button-moon {
display: flex;
justify-content: right;
margin-right: 20px;
margin-top: 20px;
}
.tw_button-sun{
display: none;
justify-content: right;
margin-right: 20px;
margin-top: 20px;
}

@media (min-width: 1025px) {
.LibraryPage-header4 {
   position: relative;
   background-image: url(https://64.media.tumblr.com/96a2bb2b0e7a4c431a7994f22b49ec37/c165fece8fe86bef-aa/s540x810/5fd010e45447bd7aee5b9e8835c47536eebcc596.gifv);
   background-size: cover;
   background-position: 50%;
   background-attachment: fixed;
   filter: brightness(60%);
   z-index: 10;
   min-height: 494px;
   text-align: center;
   padding: 35px;
}
}
@media (max-width: 1024px) {

.LibraryPage-header4 {
  background-image: url(https://i.imgur.com/qEA0AOK.gif);
  background-size: cover;
  background-position: 50%;
  filter: brightness(60%);
  z-index: 10;
  min-height: 394px;
  text-align: center;
  padding: 35px;
} 
}

@media (min-width: 769px) and (max-width: 1000px){

.LibraryPage-header4 {
  background-image: url(https://c.tenor.com/fTKfFMOURxQAAAAC/bleach-bleach-anime.gif);
  background-attachment: fixed;
  background-size: cover;
  background-position: 50%;
  z-index: 10;
  min-height: 394px;
  text-align: center;
  padding: 35px;
  filter: brightness(60%);
       filter: brightness(60%);
} 
}

@media (max-width: 1024px) {

.LibraryPage-header {
  background-size: cover;
  background-position: 50%;
  z-index: 50;
  min-height: 394px;
  text-align: center;
  padding: 35px;
} 

.LibraryPage-heade2 {
  background-image: url(https://i.imgur.com/qEA0AOK.gif);
  background-attachment: fixed;
  background-size: cover;
  background-position: 50%;
  z-index: 10;
  min-height: 394px;
  text-align: center;
  padding: 35px;
} 


}


@media (min-width: 769px) and (max-width: 1000px){

.LibraryPage-header2 {
  background-image: url(https://c.tenor.com/fTKfFMOURxQAAAAC/bleach-bleach-anime.gif);
  background-attachment: fixed;
  background-size: cover;
  background-position: 50%;
  z-index: 10;
  min-height: 394px;
  text-align: center;
  padding: 35px;
  filter: brightness(60%);
} 
}

@media (min-width: 1024px) {

.tw_button-moon {
  display: flex;
  justify-content: right;
  margin-right: 10px;
}
.tw_button-sun{
  display: none;
  justify-content: right;
  margin-right: 10px;
}


}

@media (max-width: 1024px) {

.tw_button-moon {
  display: flex;
  justify-content: right;
  margin-right: 10px;
  margin-top: 20px;
}
.tw_button-sun{
  display: none;
  justify-content: right;
  margin-right: 10px;
  margin-top: 20px;
}

}

@media (max-width: 465px) {

.tw_button-moon {
  display: flex;
  justify-content: right;
  margin-right: 10px;
  margin-top: 25px;
}
.tw_button-sun{
  display: none;
  justify-content: right;
  margin-right: 10px;
  margin-top: 25px;
}

}

.LibraryPage-header .title {
font-size: 1.5rem;
line-height: 1.5em;
text-transform: none;
font-weight: 400;
margin-top: 140px;
margin-bottom: 5px;
color: #ffffff;
}

.LibraryPage-header .subtitle {
font-size: 1.2rem;
line-height: 1.5em;
font-weight: 400;
margin-bottom: 35px;
color: rgb(176, 171, 171);
}

@media (min-width: 1025px){
.LibraryPage-header:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,.5);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
  z-index: -2;
}}

@media (min-width: 768px) {
.LibraryPage-header .subtitle {
  font-size: 1.5rem;
}}

@media (min-width: 768px){
.LibraryPage-header .title {
  font-size: 3rem;
}}


@media (min-width: 1025px){
.LibraryPage-header2:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.5);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    pointer-events: none;
    z-index: -2;
}}




@media (max-width: 768px) {
.LibraryPage-header2 {
   background-image: url(https://lecturesmmdoriane.files.wordpress.com/2021/06/sabito.gif);
   background-size: cover;
   background-position: 50%;
   z-index: 10;
   min-height: 394px;
   text-align: center;
   filter: brightness(60%);
   padding: 35px;
}



.LibraryPage-header .title {
font-size: 2.5rem;
line-height: 1.5em;
text-transform: none;
font-weight: 400;
margin-top: 140px;
margin-bottom: 5px;
}


}

@media (max-width: 1032px) {
.right{
margin-right: 0px;
}
}


@media (min-width: 50em){
.c-feed-header__divider {
   margin-top: 0.75rem;
} }

.c-feed-header__divider {
   margin-top: 0.5rem;
}


.c-feed-divider {
height: 0.25rem;
width: 100%;
background-image: linear-gradient(to left ,#213944, #5a66bf);
z-index: 0;
}

.LibraryPage-nav {
margin-left: 35px;
margin-top: 35px;
margin-bottom: 10px;
display: flex;
justify-content: center;
}

@media (min-width: 1275px) {
.LibraryPage-mobile {
  display: none;  
}

}


@media (max-width:1275px) {
.LibraryPage-nav-mobile {
  margin-left: 35px;
  margin-top: 35px;
  margin-bottom: 10px;
}

.LibraryPage-nav {
  margin-left: 35px;
  margin-top: 35px;
  margin-bottom: 10px;
  display: none;
  justify-content: center;
}
}


.LibraryPage-nav .link {
display: inline-block;
white-space: nowrap;
margin-right: 25px;
margin-bottom: 10px;
color: #646066;
}

.LibraryPage-nav .link.active {
color: #5a66bf;
}

.LibraryPage-nav .link {
color: #afafaf;
}





.LibraryPage-mobile .link.active {
color: #5a66bf;
}

.LibraryPage-mobile .link {
color: #afafaf;
}

.LibraryPage-mobile .link {
display: inline-block;
white-space: nowrap;
margin-right: 25px;
margin-bottom: 10px;
color: #646066;
}


.Ia-Content-Anime{
float: left;
width: 200px;
margin-bottom: 30px;
margin: 20px;
}

.Ia-Content-Anime-Title{
font-size: 18px;
line-height: 22px;
margin-top: 10px;
}

.Ia-Content-Anime-Image{
overflow: hidden;
border-radius: 5px;
}

.Ia-Anime-Page-List{
display: flex;
justify-content: center;
align-items: stretch;
flex-wrap: wrap;
margin: 50px;
}

.c-Ia-Title{
  font-family: "eudoxus sans";
  font-weight: bold;
  font-size: 28px;
  text-align: center;
  margin-top: 45px;
}

.c-Ia-feed-Line{
margin-top: 10px;
height: 0.25rem;
width: 100%;
background-image: linear-gradient(to left ,#213944, #5a66bf);
}

.c-Ia-Feed{
margin: 25px;
margin-right: 100px;
margin-left: 100px;
}

@media (max-width:579px) {
.Ia-Anime-Page-List {
  margin: 1px;
}

}

@media (max-width:481px) {
.Ia-Content-Anime {
  float: left;
  width: 130px;
  margin-bottom: 30px;
  margin: 20px;
}

.Ia-Content-Anime-Title{
font-size: 16px;
}
}

.LibraryPage-nav{
display: flex;
justify-content: center;
align-items: center;
flex-wrap: wrap;
}

@media (max-width:381px) {
.Ia-Content-Anime {
  float: left;
  width: 130px;
  margin-bottom: 30px;
  margin: 20px;
}

.Ia-Content-Anime-Title{
font-size: 14px;
}
}

@media (min-width:769px) and (max-width:1000px) {
.LibraryPage-header2{
background-image: url(https://lecturesmmdoriane.files.wordpress.com/2021/06/sabito.gif);
background-attachment: fixed;
background-size: cover;
background-position: 50%;
z-index: 10;
min-height: 394px;
text-align: center;
padding: 35px;
filter: brightness(60%);
}
}

@media (min-width:1001px) and (max-width:1024px) {
.LibraryPage-header2{
  background-image: url(https://lecturesmmdoriane.files.wordpress.com/2021/06/sabito.gif);
  background-attachment: fixed;
  background-size: cover;
  background-position: 50%;
  z-index: 10;
  min-height: 394px;
  text-align: center;
  padding: 35px;
  filter: brightness(60%);
}
}

.color-blue-pagination{
color: #5a66bf;
border-color: #5a66bf;
}

.color-blue-pagination:hover{
color: #5a66bf;
border-color: #5a66bf;
}

.blue-svg{
color: #5a66bf;

}

.hover-blue-PN:hover{
color: #5a66bf;
border-color: #dadada;
}




/*   PROCHAINEMENT VF        */

.Proch-Page {
overflow: hidden;
padding-top: 150px;
}

@media (max-width: 900px) {
.Proch-Page-Wrapper {
  padding: 20px 15px 50px;
}}

.Proch-Page-Wrapper {
  padding: 20px 25px 100px;
}

.Wrapper {
position: relative;
text-align: center;
}

.wrapper-top {
max-width: 853px;
width: 100%;
margin: 0 auto;
}

.Proch-Page-Image {
width: 100%;
position: relative;
margin-bottom: -11%;
}

.Proch-Page-Image img {
width: 106%;
max-width: none;
height: auto;
-webkit-transform: translateX(-50%);
transform: translateX(-50%);
margin-left: 50%;
}

@media (max-width: 900px){
.Proch-Page-Title {
  font-size: 1.5rem;
  line-height: 1.5em;
}}

.Proch-Page-Title{
  position: relative;
  text-transform: none;
  margin: 0;
  font-size: 2.25rem;
  line-height: 1.5em;
  font-weight: 500;
}

@media (max-width: 900px){
.Proch-Page-Secondary-Title {
  font-size: 1rem;
  line-height: 1.5em;
}}

.Proch-Page-Secondary-Title {
  position: relative;
  font-size: 1.25rem;
  line-height: 1.5em;
  font-weight: 400;
  color: #afafaf;
  -webkit-margin-before: 0;
  margin-block-start: 0;
  margin-top: 12px;
}


@media (min-width:10px) and (max-width: 450px){
.Proch-Page-Title {
    font-size: 1.2rem;
    line-height: 1.5em;
}}

@media (min-width:10px) and  (max-width: 450px){
.Proch-Page-Secondary-Title {
  font-size: 0.8rem;
  line-height: 1.25em;
}}

@media (min-width:451px) and (max-width: 540px){
.Proch-Page-Title {
    font-size: 1.4rem;
    line-height: 1.5em;
}}

@media (min-width:451px) and  (max-width: 540px){
.Proch-Page-Secondary-Title {
  font-size: 0.9rem;
  line-height: 1.25em;
}}

.Proch-Page-Button {
background: linear-gradient(to left ,#213944, #5a66bf);
border-radius: 15px;
font-size: 1em;
font-weight: 600;
color: #fff;
padding: 0 1.5em;
cursor: pointer;
height: 3.5em;
text-align: center;
outline: none;
margin: 0;
width: 100%;
max-width: 250px;
-webkit-transition: .15s ease-in;
transition: .15s ease-in;
}

@media (min-width:10px) and  (max-width: 450px){
.Proch-Page-Button {
  background: linear-gradient(to left ,#213944, #5a66bf);
  height: 2.5em;
}}

.Proch-Page-Secondary-Title{
margin-bottom: 25px;
}



/*      LECTEUR           */


.Ia-video-Playeer{
display: flex;
justify-content: center;
overflow-y: hidden;
}

@media (max-width:500px) {
.Ia-video-Playeer{
    display: flex;
    justify-content: center;
    margin: 0;
    overflow-y: hidden;
}
}




.dark-mode  .vjs-control-text {
color: #ffffff;
}


@media (min-width:1200px) {



.Ia-watch-Title{
padding-bottom: 20px;
}

}
.footer-anime {
padding-top: 100px;
}

.dark-mode .wh0 {
color: #ffffff;
}

.c-feed-anime {
height: 0.25rem;
width: 105px;
background-image: linear-gradient(to left ,#213944, #5a66bf);
margin-bottom: 15px;
}

.c-feed-anime__divider {
margin-top: 0.35rem;
}

@media (min-width: 50em){
.c-feed-anime__divider {
margin-top: 0.25rem;
}}

/* IFRAME RESPONSIVE */



@media (min-width:1200px) {
.Ia-video-Playeer iframe{
  min-width: 1200px;
  min-height: 675px;
  overflow-y: hidden;
}
}

@media (min-width:992px) and (max-width:1199px) {
.Ia-video-Playeer iframe{
  min-width: 930px;
  min-height: 523px;
  overflow-y: hidden;
}
}

@media (min-width:768px) and (max-width:991px) {
.Ia-video-Playeer iframe{
  min-width: 690px;
  min-height: 388px;
  overflow-y: hidden;
}
}

@media (min-width:500px) and (max-width:768px) {
.Ia-video-Playeer iframe{
  min-width: 95%;
  min-height: 293px;
  overflow-y: hidden;
}
}

@media (min-width:370px) and (max-width:389px) {
.Ia-video-Playeer iframe{
  width: 345px;
  height: 194px;
}
}

@media (min-width:390px) and (max-width:420px) {
.Ia-video-Playeer iframe{
  width: 360px;
  height: 203px;
}
}

.dark-mode .genreIa{
color: #171717!important;
}


@media (min-width:150px) and (max-width:400px) {

.text-footer{
  font-size: 0.7em;
}


.Ia-watch-anime {
  padding-top: 100px;
}
}


/*    A propos    */ 

.PostPage-article {
background-color: #fff;
border-radius: 8px;
padding: 45px 5.3%;
margin-bottom: 80px;
padding-bottom: 50px;
padding-top: 90px;
}

.container-wrapper {
position: relative;
max-width: 860px;
width: 100%;
margin: 0 auto;
overflow: hidden;
}

.PostPage-title {
text-align: center;
font-size: 22px;
font-weight: 500;
color: #000000;
}

.c-Ia-feed-Line {
margin-top: 10px;
height: 0.25rem;
width: 100%;
background-image: linear-gradient(to left ,#213944, #5a66bf); 
color: #000000;
}

.PostPage-article h1 {
color: #000000;
font-size: 32px;
font-weight: 600;
line-height: 1.25;
letter-spacing: -.5px;
margin-bottom: 15px;
}

.PostPage-article-img{
margin-top: 25px;
}


.CPinfo{
color: #272c35;

}

.CPinfo p {
margin: 1.7em 0;
font-size: 16px;
text-align: justify;
font-family: 'Nunito Sans', Arial;
}

.CPfinal p{
margin: 1.7em 0;
font-size: 16px;
text-align: center;
font-family: 'Nunito Sans', Arial;
}

.CPfinal{
color: #272c35;
margin-top: 50px;
}

.c-Ia-feed-Line3{
margin-top: 10px;
height: 0.25rem;
width: 100%;
background-image: linear-gradient(to left ,#213944, #5a66bf); 
transform: scale(50%);
}

.c-Ia-feed-Line3:hover{
margin-top: 10px;
height: 0.25rem;
width: 100%;
background-image: linear-gradient(to left ,#213944, #5a66bf); 
transform: scalex(50%);
}

.top{
font-family: 'Nunito Sans', sans-serif;
font-family: 'Poppins', sans-serif;
margin: 10px 0;
font-size: 1.05em;
margin-bottom: 10px;
margin-top: 10px;
}

.top-1{
color: #5a66bf;
font-weight: 600;
}

.top-2{
color: #5a66bf;
font-weight: 600;
}

.top-3:hover{
color: #ffffff;
font-weight: 600;
}

.Iw-team-card{
background-color: #dadada;
box-shadow: 5px 5px 50px #5a66bf;
border: #5a66bf;
width: 40%;
height: 225px;
display: flex;
justify-content: center;
align-items: center;
margin-left: 50px;
margin-bottom: 50px;
}

.Iw-team-pp{
width : 100px; 
height : 100px;
margin-top: -100px;
}

.Iw-pp{
border-radius: 50%;
}

.Iw-team-text{
font-family: "eudoxus sans";
font-weight: bold;
font-size: 24px;
text-align: center;
color: #000;
}

.Iw-Team{
display: flex;
justify-content: center;
align-items: stretch;
flex-wrap: wrap;
}


.Slider__item {
margin-left: 15px;
margin-bottom: 15px;
}


.testtt{
display: flex;
flex-direction: row;
justify-content: center;
flex-wrap: wrap;
margin-top: 30px;
}

.card-title{
font-size: 16px;
font-weight: 400;
}

.card-genre{
margin-top: 5px ;
margin-bottom: 10px ;
font-size: 10px;
}

.card{
margin-top: 180px ;
}

.text{
transition: transform 500ms ease-in-out;
position: relative;
z-index: 10;
}

.PortraitTile__wrapper:hover .text{
transform: translateY(-100px);
}

.card-des{
opacity: 0;
animation: fadeout 500ms ease-in-out;
max-width: 250px;
font-size: 12px;
}

.PortraitTile__wrapper:hover .card-des{
opacity: 100%;
animation: fadein 500ms ease-in-out;
}

@media (min-width: 1024px){
.PortraitTile:hover .PortraitTile__wrapper {
transform: scale(1.03);
box-shadow: 0 24px 88px -8px rgb(4 4 5 / 40%);
}}

.PortraitTile__wrapper {
position: relative;
bottom: 0;
overflow: hidden;
transition: transform 0.4s ease, box-shadow 0.4s ease;
width: 185px;
height: 280px;
border-radius: 4px;
}

.PortraitTile__base, .PortraitTile__art, .PortraitTile__gradient, .PortraitTile__highlight, .PortraitTile__editorial, .PortraitTile__scrim {
position: absolute;
top: -2px;
right: -2px;
bottom: -2px;
left: -2px;
}

@media (min-width: 1024px){
.PortraitTile:hover .PortraitTile__art {
opacity: 0.4;
transform: scale(1.08);
}}

.PortraitTile__art {
width: 185px;
height: 280px;
opacity: 0.96;
transition: opacity 1s,transform 12s cubic-bezier(0.23, 1, 0.32, 1);
}

@media (min-width: 1024px){
.PortraitTile__gradient, .PortraitTile__highlight, .PortraitTile__overlay {
display: block;
}}

@media (min-width: 1024px){
.PortraitTile__content {
margin: 32px;
}}

.PortraitTile__content {
position: absolute;
top: 0;
right: 0;
bottom: 0;
left: 0;
margin: 24px;
}

@media (max-width:444px) {

.PortraitTile__art {
  width: 125px;
  height: 190px;
}

.PortraitTile__wrapper {
  width: 125px;
  height: 190px;
}

.card{
  margin-top: 100px ;
}

.card-des{
  opacity: 0;
  animation: fadeout 500ms ease-in-out;
  max-width: 250px;
  font-size: 10px;
}



}

@media (min-width:390px) and (max-width: 444px) {
.testtt {
  display: flex;
  flex-direction: row;
  justify-content: left;
  flex-wrap: wrap;
  margin-top: 30px;
  margin-left: 30px;
}
}

.darkmode .search {
background: #000000;
color: #000000;
}

.search {
display: flex;
width: 100%;
position: relative;
height: 40px;
background: rgba(255,255,255,.1);
color: #f9f9f9;
border-radius: 5px;
outline: none;
}

.search input {
margin-left: 15px;
border: 0;
outline: none;
width: 100%;
background: 0;
}

.search-button {
border: 0;
position: absolute;
right: 0;
top: 0;
cursor: pointer;
padding: 10px 15px;
font-size: 14px;
}

.search-button i{
color: #fff;
}


.modal-content .close{
font-size: 50px;
margin-top: -60px;
}

.close:hover,
.close:focus {
color: #5a66bf;
text-decoration: none;
cursor: pointer;
}


.lastep-pag{
margin-top: 1.5rem;
margin-bottom: 1.5rem;
text-align: center;
}

.lastep-pag a {
display: inline-block;
font-size: 0.85rem;
padding: 0.5375em 0.925em;
color: #ffffff;
text-align: center;
min-width: 3em;
}

.lastep-pag a svg {
  fill: #b9b9b9;
  width: 24px;
  height: 24px;
  margin: 0;
  top: 7px;
  position: relative;
}


.lastep-prev-page.disabled{
opacity: 0.4;
}


@media (min-width: 100px) and (max-width: 380px){
.testtt {
  margin-left: -10px!important;
}}


/*  selection */

a::selection {
color: #ffffff;
background-color: #5a66bf;
}

p::selection {
color: #ffffff;
background-color: #5a66bf;
}

ul::selection {
color: #ffffff;
background-color: #5a66bf;
}

div::selection {
color: #ffffff;
background-color: #5a66bf;
}

h6::selection {
color: #ffffff;
background-color: #5a66bf;
}

h5::selection {
color: #ffffff;
background-color: #5a66bf;
}

h4::selection {
color: #ffffff;
background-color: #5a66bf;
}

h3::selection {
color: #ffffff;
background-color: #5a66bf;
}

h2::selection {
color: #ffffff;
background-color: #5a66bf;
}

h1::selection {
color: #ffffff;
background-color: #5a66bf;
}

span::selection {
color: #ffffff;
background-color: #5a66bf;
}

img::selection {
color: #ffffff;
background-color: #5a66bf;
}

img {
user-select: none;
-moz-user-select: none;
-webkit-user-drag: none;
-webkit-user-select: none;
-ms-user-select: none;
}

svg {
user-select: none;
-moz-user-select: none;
-webkit-user-drag: none;
-webkit-user-select: none;
-ms-user-select: none;
}


.IA-footer {
  border: 0 solid #e1e1e1;
}
.IA-footer {
  padding: 15px;
  border-radius: 1em;
  box-shadow: 0 0 10px 1px rgb(0 0 0 / 18%);
  background-image: linear-gradient(to left ,#355b6d, #5a66bf);
  filter: brightness(70%);
  margin-right: 150px;
  margin-left: 150px;
}

.footer-anime {
  margin-top: 100px;
  margin-bottom: 60px;
  padding-left: 25px;
  padding-right: 25px;
}

.text-footer{
  text-align: center;
  color: #ffffff;
  filter: none;
}



@media (max-width: 600px) {

  .IA-footer {
      margin-right: 0px;
      margin-left: 0px;
  }
  
}

.link-item{
  display: flex;
  justify-content: center;
  margin-bottom: 25px;
  margin-top: 25px;
  filter: brightness(70%);
}





.gradient-overlay {
    background: -webkit-linear-gradient(top,hsla(0,0%,9%,.2),#171717);
    height: 525px;
    left: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
    pointer-events: none;
}
@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://stijndv.com/fonts/Eudoxus-Sans.css');


.list-icon {
  margin-top:10px;
  color:#5a66bf;
  font-size:13px;
  line-height:20px;
  overflow:hidden;
  text-overflow:ellipsis;
  white-space:nowrap;
}


.heart {
  color:#5a66bf;
  width:12px;
  height:12px;
  margin-left:5px;
  margin-right:5px;
}

.list {
  width:100%;
  margin-bottom:30px;
  overflow:hidden;
}

.list-item {
  width:1200px;
  margin:30px auto;
  overflow:hidden;
  position:relative;
}

.item-title {
  font-family: "eudoxus sans";
  font-weight:bold;
  font-size:28px;
  overflow:hidden;
  text-overflow:ellipsis;
  white-space:nowrap;
}

.hot-content-top2-bg {
  width: 100%;
  overflow: hidden;
  margin: 10px auto;
  display: flex;
}

.content-1 {
  float:left;
  width:270px;
  margin-bottom:30px;
}

.content-1 .content-image {
  height:211px;
  width:100%;
  object-fit:cover;
}

.top-content-image {
  height: 245px;
  width: 185px;
  float: left;
}

.top-content-image img {
  border-radius: 4px;
}

.top-content-info {
  width: 328px;
  margin: 10px 30px 20px 17px;
  overflow: hidden;
  float: left;
}

.top-content-info-title {
  font-family: "eudoxus sans";
  height: 29px;
  font-size: 20px;
  font-weight: 500;
  line-height: 29px;
  color: #000000;
  overflow: hidden;
  text-overflow:ellipsis;
  white-space: nowrap;
}

.top-content-info-tag {
  overflow: hidden;
  text-overflow:ellipsis;
  white-space: nowrap;
  height: 20px;
  font-size: 14px;
  font-family: Noto Sans SC;
  font-weight: 400;
  line-height: 20px;
  color: #888888;
}

.hot-content1{ 
  width: 590px;
  height: 275px;
  overflow: hidden;
  margin-right: 15px;
  padding: 15px;
  box-sizing: border-box;
  /*background-image: url("/images/contribute/hot-content-bg.png");*/
  background-size: 100% 100%;
} 

.hot-content2 {
  margin-right: 0;
}

.view-icon {
  color: #5a66bf;
  margin-right: 2px;
}

.view-count { 
  display: inline-block;
  margin-right: 15px;
}

.like-icon {
  color: #5a66bf; 
  margin-right: 2px; 
}

.detail-views-likes { 
  height:20px;
  width:14pt;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
}

.top-content-info-description{
  overflow: hidden;
  text-overflow:ellipsis;
  display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 4;
  font-size: 14px;
  font-family: 'Noto Sans SC';
  font-weight: 400;
  line-height: 20px;
  color: #888888;
  margin-top: 20px;
}

.item-2 {
  margin-top: 30px;
  overflow: hidden;
  margin-bottom: 0;
}

.content-3 {
  float: left;
  width: 175px;
  margin-bottom: 30px;
}

.content-4 {
  float: left;
  width: 175px;
  margin-left: 30px;
  margin-bottom: 30px;
}

.see-all {
  position: absolute;
  right: 0px;
  top: 10px;
  color: #5a66bf;
  font-size: 18px;
}

.content-title {
  font-size: 18px;
  line-height: 22px;
  margin-top: 5px;
}

.content-image {
  overflow: hidden;
  border-radius: 5px;
}

:root{
    --colors-black: rgb(0, 0, 0);
}


@media only screen and (max-width:800px){
  .content-3 { 
    float:left;
    width:32%;
    margin-bottom:20px;
    margin-right:2%;
  }


  .content-4 {
    float:left;
    width: 32%;
    margin-bottom:20px;
  }

  .content-3 .content-image {
    height:140px;
    width:100%;
  }

  .content-4 .content-image {
    height:140px;
    width:100%;
    object-fit: cover;
  }

  .content-4:nth-child(3n) {
    margin-left:0;
    margin-right:0;
  }

  .content-4:nth-child(3n+1) {
    margin-left:0;
    margin-right:3px;
  }

  .content-4:nth-child(3n+2) {
    margin-left:0;
    margin-right:3px;
  }
  
  .content-title {
    font-size:10px;
    line-height:13px;
    margin-left:0;
    margin-top:5px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .subtitle {
    font-size:12px;
    margin-left:5px;
    margin-top:0;
  }

  .list-icon { 
    font-size:12px;
    margin-top:5px;
  }

  #list-channels {
    margin-left:5px;
  }

  .list {
    margin: 0 auto;
    width: 90%;
  }
  .list-item {
    width:100%;
    margin:20px 0;
  }

  .see-all {
    font-size:14px;
    right:10px;
  }
 
  .hot-content-top2-bg {
    display: block;
  }

  .hot-content1 {
    padding: 2%;
    width: 150%;
    height: 210px;
    margin-bottom: 3%;
  }

  .top-content-image {
    width: 28%;
    height: 200px;
  }
  .top-content-info {
    width: 50%;
    margin: 0 0 0 3%;
  }

  .top-content-info-description {
    width: 70%;
    font-size: 12px;
    line-height: 18px;
  }

  .top-content-info-tag {
    width: 80%;
  }

  .top-content-info-title {
    width: 70%;
    font-size: 16px;
  }

  .view-icon {
    font-size: 16px;
  }

  .view-count {
    font-size: 12px;
  }

  .like-icon {
    font-size: 14px;
  }

  .like-count {
    font-size: 12px;
  }

}

/*
@font-face {font-family: 'iconfont';
  src: url('iconfont.eot');  IE9
  src: url('iconfont.eot?#iefix') format('embedded-opentype'), IE6-IE8 
  url('iconfont.woff') format('woff'), /* chrome、firefox 
  url('iconfont.ttf') format('truetype'), /* chrome、firefox、opera、Safari, Android, iOS 4.2+
  url('iconfont.svg#iconfont') format('svg'); /* iOS 4.1- 
}

.iconfont{
  font-family:"iconfont" !important;
  font-size:13px;font-style:normal;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.2px;
  -moz-osx-font-smoothing: grayscale;    
}

*/
li {
  list-style: none;
  display: inline;
}

.tw-header__Anime li{
  margin-right: 50px;
  font-family: "eudoxus sans";
  font-weight: 500;
  font-size: 20px;
  color: rgb(255, 255, 255);;
}

.tw-header__Vostfr li{
  font-family: "eudoxus sans";
  font-weight: 500;
  font-size: 20px;
  color: rgb(255, 255, 255);;
}

.n {
  height: 0.95rem;
  width: 0.95rem;
  overflow: hidden;
  margin-right: 8px;
}

/*   LECTEUR */ 

.Ia-watch-anime{
padding-top: 100px;
}



.Ia-watch-container{
margin: 25px;
}

@media  (max-width:575px) {
.Ia-watch-container {
  margin: 0px;
}
}

.Ia-watch-Title{
font-family: "eudoxus sans";
font-weight: bold;
font-size: 16px;
text-align: center;
}


@media (min-width:150px) and (max-width:400px) {




.Ia-watch-anime {
  padding-top: 100px;
}

.footer-anime {
padding-top: 50px;
}
}

.text-bb{
color: #5a66bf;
}

.text-bb2{
background: linear-gradient(to left ,#213944, #5a66bf);
}

.grid.minia{
margin-left: -10px;
}

.anime-titre{
font-size: 22px;
}

footer {
  background: url(https://lesnouveauxfromagers.fr/themes/lnf/img/bg-footer.jpg) repeat top left;
}

.container {
  max-width: 1300px; /* Largeur maximale du conteneur */
  margin-left: auto; /* Centre le conteneur à gauche */
  margin-right: auto; /* Centre le conteneur à droite */
  padding-left: 15px; /* Marge intérieure à gauche pour plus d'espace */
  padding-right: 15px; /* Marge intérieure à droite pour plus d'espace */
  box-sizing: border-box; /* Inclut les marges et les bordures dans la largeur totale */

  /* Responsive styles */
  @media screen and (max-width: 768px) {
    /* Ajustements pour les écrans plus petits que 768 pixels de largeur */
    max-width: 100%;
    padding-left: 10px;
    padding-right: 10px;
  }
}


.button-EP{
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.uibutton.with-svg-left {
  position: relative;
  padding-left: 1.5em;
}

.uibutton.with-svg-right {
position: relative;
padding-right: 1.5em;
}

.uibutton svg {
fill: #5a66bf;
position: absolute;
}

.uibutton:hover {
  fill: #fff;
}

svg.a {
height: 0.95rem;
width: 0.95rem;
overflow: hidden;
}

svg.a:hover{
  fill: #fff;

}

.uibutton {
color: #f9f9f9;
background-color: #171717;
border: 2px solid #221f1f;
border-radius: 10px;
box-shadow: none;
font-weight: 700;
padding: 0.8em;
min-width: 72px;
}

.uibutton.with-svg-left svg {
top: 50%;
transform: translateY(-50%);
left: 0.3em;
}

.uibutton.with-svg-right svg {
top: 50%;
transform: translateY(-50%);
right: 0.3em;
}

.fab.fa-youtube{
color: #5a66bf;
}

@media (max-width:450px) {
.fab.fa-youtube{
  color: #5a66bf;
  margin-left: 0px;
}
}

.Ia-Anime-Page-ep {
display: flex;
align-items: center;
flex-wrap: wrap;
}

@media (max-width:800px) {
.Ia-Anime-Page-ep {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

}

@media (min-width:801px) and (max-width: 1024px) {
.Ia-Anime-Page-ep {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

}

@media (min-width: 1024px) and (max-width: 1487px){

.lg\:mx-96 {
  margin-left: 10rem!important;
  margin-right: 4rem!important; 
}
}


/*        MODAL  */
/* The Modal (background) */
.modal {
display: none; /* Hidden by default */
position: fixed; /* Stay in place */
z-index: 1002; /* Sit on top */
padding-top: 100px; /* Location of the box */
left: 0;
top: 0;
width: 100%; /* Full width */
height: 100%; /* Full height */
overflow: auto; /* Enable scroll if needed */
background-color: rgb(0,0,0); /* Fallback color */
background-color: rgba(0,0,0,0.4); 
}

/* Modal Content */
.modal-content {
display: flex;
justify-content: center;
margin-top: 200px;
}

.modal-content iframe {
border: 3px solid rgb(255, 255, 255);
}

@media (min-width:500px) and (max-width:550px) {
.modal-content iframe {
  width: 95%;
  height: 293px;
}
}

@media (min-width:370px) and (max-width:389px) {
.modal-content iframe {
  width: 345px;
  height: 194px;
}
}

@media (min-width:390px) and (max-width:420px) {
.modal-content iframe {
  width: 360px;
  height: 203px;
}
}

.last-ep {
display: flex;
justify-content: center;
align-items: center;
flex-wrap: wrap;
margin: 15px;
}


@media only screen and (min-width: 768px){
.last-ep-content {
width: 20%;
margin-right: 25px;
margin-bottom: 15px;
}}

@media only screen and (max-width: 450px){
.last-ep-content {
  width: 40%;
  margin-right: 25px;
  margin-bottom: 15px;
}

.last-ep{
  margin-left: 30px;
}

}

@media only screen and (min-width: 451px) and (max-width:767px){
.last-ep-content {
  width: 30%;
  margin-right: 25px;
  margin-bottom: 15px;
}

.last-ep{
  margin-left: 30px;
}

}

.content-image-lastep img {
border-radius: 12px;
}

.content-subtitle{
font-size: 13px;
color: rgb(104, 104, 104);
}

@media only screen and (max-width: 800px){
.content-subtitle {
  font-size: 10px;
  line-height: 13px;
  margin-left: 0;
  margin-top: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.list-item{
  margin: 10px;
}
}


@media only screen and (max-width: 482px){
.view-count {
  font-size: 7px;
}
.like-count {
font-size: 7px;
}

}


.Ia-Header {
flex: 0 0 3.75rem;
min-width: 20rem;
position: relative;
}

.Ia-header-content {
align-items: stretch;
background-color: rgb(31, 30, 30);
display: flex;
height: 4.2rem;
justify-content: space-between;
left: 0;
min-width: 20rem;
position: fixed;
right: 0;
z-index: 1000;
}

@media (min-width: 61.25em){
.header-content {
justify-content: center;
}}

.Ia-header-logo{
display: flex;
height: inherit;
}

@media (min-width: 61.25em){
.Ia-logo {
padding-left: 1.5625rem;
padding-right: 1.5625rem;
}}

@media (min-width: 35.5em){
.Ia-logo {
padding-left: 1.25rem;
padding-right: 1.25rem;
}}

.Ia-logo  {
display: block;
height: inherit;
}

.Ia-logo img {
display: flex;
width: 150px;
height: 40px;
margin-top: 15px;
margin-left: 150px;
}

.Ia-header-left {
margin-top: 25px;
margin-left: -225px;
}

.Ia-header-left span{
margin-right: 50px;
font-family: "eudoxus sans";
font-weight: 500;
font-size: 18px;
color: #dadada;
}



@media (min-width: 1024px){
.tw_button-sun {
display: none;
justify-content: right;
margin-right: 10px;
}}

.tw_button-sun {
display: none;
justify-content: right;
margin-right: 20px;
margin-top: 20px;
}
.tw_button-sun {
display: none;
justify-content: right;
margin-right: 10px;
margin-top: -35px;
}

@media (min-width: 1024px){
.tw_button-moon {
display: flex;
justify-content: right;
margin-right: 10px;
}}

.tw_button-moon {
display: flex;
justify-content: right;
margin-right: 20px;
margin-top: 20px;
}
.tw_button-moon {
display: flex;
justify-content: right;
margin-right: 10px;
margin-top: -38px;
}

.bg {
animation: pulse 1.2s ease infinite;
background: #5a66bf;
}
.bg, .button {
position: absolute;
width: 32px;
height: 32px;
border-radius: 100%;
}

.button {
display: flex;
justify-content: center;
align-items: center;
position: absolute;
z-index: 99;
border: none;
background-color: #5a66bf;
background-size: 9px;
cursor: pointer;
outline: none;
}
.bg, .button {
position: absolute;
width: 32px;
height: 32px;
border-radius: 100%;
}

.button a {
position: absolute;
color: rgb(255, 255, 255);
font-size: 15px;
}


.Ia-header-right{
margin-right: 40px;
margin-top: 55px;
}

@media (min-width:1201px) and (max-width:2000px){

.tw-header__Mobile{
display: none;
}
}


@media (min-width:937px) and (max-width:1200px) {
.Ia-logo img{
    margin-left: 50px;
}

.Ia-header-left {
    margin-top: 25px;
    margin-left: 0px;
}

.tw-header__Mobile{
    display: none;
}
}

@media (min-width:100px) and (max-width:936px) {
.Ia-logo img{
    margin-left: 0px;
}

.Ia-header-left {
    display: none;
}



.tw-header__Mobile-menu {
    z-index: 99;
    display: flex;
    margin-left: 25px;
    margin-top: 25px;
}

.tw-header__Mobile-menu {
    width: 15px;
    height: 40px;
    flex-direction: column;
    cursor: pointer;
    
}


}



.stick {
width: 23px;
height: 3px;
border-radius: 3px;
margin-bottom: 5px;
background-color: #ffffff;
display: inline-block;
}

.tw-header__Mobile button {  
-webkit-appearance: button;
background-color: transparent;
background-image: none;
}

@media only screen and (max-width: 465px){
.bg, .button {
position: absolute;
width: 25px;
height: 25px;
border-radius: 100%;
}
}

@media (max-width: 465px){
.tw_button-moon {
display: flex;
justify-content: right;
margin-right: 10px;
margin-top: -30px;
}}

@media (max-width: 465px){
.tw_button-sun {
display: none;
justify-content: right;
margin-right: 10px;
margin-top: -30px;
}}

@media only screen and (max-width: 465px){
.button a {
position: absolute;
color: #FFF;
font-size: 12px;
}

.stick {
width: 22px;
height: 3px;
border-radius: 3px;
margin-bottom: 4px;
background-color: #ffffff;
display: inline-block;
}

.tw-header__Mobile-menu {
z-index: 99;
display: flex;
margin-left: 25px;
margin-top: 28px;
}

.Ia-header-right {
margin-right: 25px;
margin-top: 55px;
}

.Ia-logo img {
display: flex;
width: 180px;
height: 40px;
margin-top: 15px;
margin-left: -40px;
}

}

@media (min-width: 936px){
.lg\:hidden {
display: none;
}}






@media (max-width: 799px){
.span-genre{
  font-size: 0.675rem!important;
  line-height: 1.25rem!important;
}

}


.dd{
  fill: #5a66bf;
  background: rgba(0, 0, 0, 0.75);
  width: 4em;
  height: 4em;
  border-radius: 5em;
  margin: 0;
  margin-top: -120px;
  margin-bottom: 60px;
  opacity: 0;
}

.ss:hover .dd{
opacity: 100%;
transition: 1s;
}

.sn {
background: linear-gradient(to bottom ,#2139446b, #5a66bf57)!important;
}

.darkmode .sn {
background: linear-gradient(to bottom ,#213944, #5a66bf)!important;
}


@media (min-width: 768px){
.col-md-4 {
  flex: 0 0 33.3333333333%;
  max-width: 24%;
}
}

@media (max-width: 767px){
.col-md-4 {
  flex: 0 0 33.3333333333%;
  max-width: 40%;
}
}

.ma-thumbnail {
font-size: 0.65rem;
font-weight: 700;
margin: 0.75em;
}

.d-block {
display: block !important;
}

.ma-thumbnail > .Last-ep-holder {
position: relative;
padding: 0 0 56.25%;
overflow: hidden;
border-radius: 12px;
}

.ma-thumbnail > .Last-ep-holder > .overlay.last-ep-dark {
background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.5) 0, rgba(0, 0, 0, 0.05) 50%, rgba(0, 0, 0, 0.05) 50%, rgba(0, 0, 0, 0.5) 100%);
background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5) 0, rgba(0, 0, 0, 0.05) 50%, rgba(0, 0, 0, 0.05) 50%, rgba(0, 0, 0, 0.5) 100%);
}

.ma-thumbnail > .Last-ep-holder > .overlay {
z-index: 1;
width: 100%;
height: 100%;
position: absolute;
}

.ma-thumbnail > .Last-ep-holder > img {
z-index: 0;
position: absolute;
width: 100%;
height: 100%;
object-fit: cover;
background-size: cover;
background-position: center center;
background-repeat: no-repeat;
}

.ma-thumbnail > .text {
padding: 0.5em 0;
}

.ma-thumbnail > .text > .title {
display: -webkit-box;
display: -ms-flexbox;
display: flex;
-webkit-box-align: center;
-ms-flex-align: center;
align-items: center;
-webkit-box-pack: justify;
-ms-flex-pack: justify;
justify-content: space-between;
}

.ma-thumbnail > .text > .title > .limit {
-webkit-box-flex: 1;
-ms-flex: 1;
flex: 1;
font-size: 0.8rem;
overflow: hidden;
white-space: nowrap;
text-overflow: ellipsis;
}

.ma-thumbnail > .text > .title > .episode {
padding-left: 1em;
text-align: right;
color: #b9b9b9;
text-transform: uppercase;
font-weight: 400;
}

.ma-thumbnail > .Last-ep-holder > .overlay > .last-ep-time {
-webkit-transition: opacity 0.2s ease-out;
transition: opacity 0.2s ease-out;
position: absolute;
top: 5px;
left: 10px;
text-transform: uppercase;
opacity: 1;
}

.ma-thumbnail > .Last-ep-holder > .overlay > .play {
z-index: 1;
width: 100%;
height: 100%;
position: absolute;
-webkit-transition: opacity 0.2s ease-in;
transition: opacity 0.2s ease-in;
display: -webkit-box;
display: -ms-flexbox;
display: flex;
-webkit-box-align: center;
-ms-flex-align: center;
align-items: center;
-webkit-box-pack: center;
-ms-flex-pack: center;
justify-content: center;
opacity: 0;
}

.ma-thumbnail > .Last-ep-holder > .overlay > .play > svg {
fill: #5a66bf;
background: rgba(0, 0, 0, 0.75);
width: 4em;
height: 4em;
border-radius: 5em;
margin: 0;
}

.ma-thumbnail > .Last-ep-holder:hover > .overlay > .play {
-webkit-transition: opacity 0.2s ease-in;
transition: opacity 0.2s ease-in;
opacity: 100%;
}

.ma-thumbnail > .Last-ep-holder:hover > .overlay > .last-ep-time {
-webkit-transition: opacity 0.2s ease-out;
transition: opacity 0.2s ease-out;
position: absolute;
top: 5px;
left: 10px;
text-transform: uppercase;
opacity: 0;
}

@media (max-width:760px){
.ma-thumbnail > .Last-ep-holder > .overlay > .play > svg {
  fill: #5a66bf;
  background: rgba(0, 0, 0, 0.75);
  width: 3.5em;
  height: 3.5em;
  border-radius: 5em;
  margin: 0;
}
}

.LASTep{
display: flex;
flex-wrap: wrap;
justify-content: center;
align-items: center;
}

.button-page-anime{
display: flex;
justify-content: center;
align-items: center;
}

@media (min-width:1000px){
.content-image img{
width:100%;
  height:250px;
}
}

@media (min-width:1000px){
.Ia-Content-Anime-Image img {
width:100%;
  height:290px;
}
}

.item-title2 {
  font-family: "eudoxus sans";
  font-weight: bold;
  font-size: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}


@media (min-width:150px) and (max-width: 481px) {
.Ia-Content-Anime-Image img {
  width: 130px!important;
  height: 195px!important;
}

}


.inupagination {
margin-top: 1.5rem;
margin-bottom: 1.5rem;
text-align: center;
}


.inupagination a, .inupagination span {
display: inline-block;
font-size: 0.85rem;
padding: 0.5375em 0.925em;
color: #b9b9b9;
text-align: center;
min-width: 3em;
-webkit-transition: color 0.15s ease-in;
transition: color 0.15s ease-in;
}



.inupagination a svg, .inupagination li svg {
fill: #b9b9b9;
width: 24px;
height: 24px;
margin: 0;
top: 15px;
position: relative;
}

.inupagination a.active, .inupagination li.active span {
border-radius: 20%;
background-color: #5a66bf;
filter: brightness(60%);
}

.LibraryPage-nav a:hover {
color: #5a66bf;
}


/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color:  linear-gradient(to bottom ,#213944, #5a66bf);
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 11px;
}

*::-webkit-scrollbar-track {
  background: rgb(31, 30, 30);
}

*::-webkit-scrollbar-thumb {
  background-image: linear-gradient(to bottom ,#213944, #5a66bf);
  border-radius: 20px;
  border: 3px solid rgb(31, 30, 30);
}